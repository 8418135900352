import { Component, Vue } from 'vue-property-decorator'

@Component({
  template: require('template/about.html'),
  components: {}
})
export default class About extends Vue {

  repo: string = ''
  pageTitle: string = 'СДПК'

  get projectDisableCourses () {
    return Vue.localStorage.get('project.disableCourses') === 'true'
  }
}
