import a, { AxiosPromise } from 'axios'
import GridDescription from 'model/grid/GridDescription'
import TestProject from 'model/TestProject'
import TestRestrictionRole from 'model/TestRestrictionRole'
import FiltersAPI from './FiltersAPI'
import User from 'model/User'
import TestData from 'model/TestData'
import UserReview from 'model/UserReview'
import SimpleResultsFilterData from 'component/simple-results-filter-data'

export default class API {
  public static address: string = ((window as any).Skt.settings.host) ? (window as any).Skt.settings.host : ''

  public static api: string = API.address + '/api'
  public static api2: string = API.address + '/api2'
  public static uniApi: string = API.address + '/uni-api'
  public static auth: string = API.api + '/auth'
  public static admin: string = API.address + '/admin'

  public static get (entityName: string, id: string, suffix?: string): AxiosPromise<any> {
    return a.get(API.api + '/get/' + entityName + '/id/' + id + (suffix ? '/' + suffix : ''))
  }

  public static getAll (entityName: string): AxiosPromise<any[]> {
    return a.get(API.api + '/get/' + entityName + '/all')
  }

  public static getBy (entityName: string, filteringClass: string, filteringField: string, filterObj: any, orderObj: any | null): AxiosPromise<any[]> {
    return a.post(API.api + '/get/' + entityName + '/by/' + filteringClass + '/' + filteringField, {
      filter: filterObj,
      order: orderObj
    })
  }

  public static getHeaders (entityName: string, categoryId: string): AxiosPromise<any[]> {
    return a.get(API.api + '/get/' + entityName + '/headers/' + categoryId)
  }

  public static remove (entityName: string, id: string): any {
    return a.get(API.api + '/delete/' + entityName + '/' + id)
  }

  public static edit (entityName: string, entity: any): any {
    return null
  }

  public static save (entityName: string, id: string, model: any): AxiosPromise<any> {
    if (!id) {
      id = 'new'
    }
    return a.post(API.api + '/save/' + entityName + '/' + id, model)
  }

  public static duplicateTest (testId: string): AxiosPromise<string> {
    return a.post(API.api2 + '/constructor/test/' + testId + '/duplicate.json')
  }

  public static getTestProjects (): AxiosPromise<TestProject[]> {
    return a.get(API.api2 + '/test-project/list.json')
  }

  public static getCurrentTestProjects (): AxiosPromise<TestProject[]> {
    return a.get(API.api2 + '/test-project/current')
  }

  public static getGrid (gridLabel: string): AxiosPromise<GridDescription> {
    const getGridUrl = API.api2 + '/grid/' + gridLabel + '/description.json'
    return a.get(getGridUrl)
  }

  public static getGridData (gridLabel: string): AxiosPromise<any> {
    const url = API.api2 + '/grid/' + gridLabel + '/data.json'
    return a.get(url)
  }

  public static saveTestProject (testProject: TestProject, testProjectId: string): AxiosPromise<string> {
    const url = API.api2 + '/test-projects/saves'
    return a.post(url, testProject)
  }

  public static getTests (filters = {}) {
    const url = API.api2 + '/test/list.json'
    return a.get(url, {
      params: filters
    })
  }

  public static filters = FiltersAPI

  public static authorizeThroughNpEdu (lazyOauthToken: string): AxiosPromise<string> {
    const url = API.api2 + '/oauths/' + lazyOauthToken + '/npedu.json'
    return a.get(url)
  }

  public static getTestProjectSplash (projectId: string): AxiosPromise<TestProject> {
    const url = API.api2 + '/test-projects/' + projectId + '/splash'
    return a.get(url)
  }

  public static getCurrentTestRestrictionRoles (): AxiosPromise<TestRestrictionRole[]> {
    return a.get(API.api2 + '/test-project/restrictions')
  }

  public static getUsersInTestProject (testProjectId: string, limit: number, offset: number): AxiosPromise<User[]> {
    return a.get(API.api2 + '/test-projects/' + testProjectId + '/users/' + limit + '/offsets/' + offset)
  }

  public static getUsersInTestProjectCount (testProjectId: string): AxiosPromise<number> {
    return a.get(API.api2 + '/test-projects/' + testProjectId + '/users/count')
  }

  public static deleteUserResult (userId: string): AxiosPromise<string> {
    return a.delete(API.api2 + '/test-projects/' + userId + '/user/result')
  }

  static getUnfinishedTestTracksCount (): AxiosPromise<number> {
    return a.get(API.api2 + '/test-track/unfinished/count')
  }

  static getTestsInTestProject (testProjectId: string): AxiosPromise<TestData[]> {
    return a.get(API.api2 + '/test-projects/' + testProjectId + '/tests')
  }

  static getUserReviews (filter: SimpleResultsFilterData): AxiosPromise<UserReview[]> {
    return a.post(API.api2 + '/user-reviews/lists.json', filter)
  }
}
